import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoInfo(props) {  
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');
  const [app, setApp] = React.useState('');
  const [appError, setAppError] = React.useState('');
  const [apm, setApm] = React.useState('');
  const [apmError, setApmError] = React.useState('');
  const [usuario, setUsuario] = React.useState('');
  const [usuarioError, setUsuarioError] = React.useState('');
  const [estado, setEstado] = React.useState('A');
  const [sexo, setSexo] = React.useState('M');
  const [celular, setCelular] = React.useState('');
  const [celularError, setCelularError] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [mailError, setMailError] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    setNombre(props.arrayDatos.nombre);
    setApp(props.arrayDatos.aPaterno);
    setApm(props.arrayDatos.aMaterno);
    setUsuario(props.arrayDatos.usuario);
    setSexo(props.arrayDatos.sexo);
    setEstado(props.arrayDatos.estado); 
    setCelular(props.arrayDatos.celular);
    setMail(props.arrayDatos.mail);
  }, []);

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='md'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Información
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={4}>
              <TextField id="nombre" label="Nombre" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="app" label="A. Paterno" variant="outlined" style={{width:'100%'}}               
                value={app}
                onChange={(event) => setApp(event.target.value)}
                error={appError}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="apm" label="A. Materno" variant="outlined" style={{width:'100%'}}               
                value={apm}
                onChange={(event) => setApm(event.target.value)}
                error={apmError}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="usuario" label="Usuario" variant="outlined" style={{width:'100%'}}               
                value={usuario}
                onChange={(event) => setUsuario(event.target.value)}
                error={usuarioError}
                disabled
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sexo"
                value={sexo}
                style={{width: '100%'}}
                onChange={(event) => setSexo(event.target.value)}
                disabled
              >
                <MenuItem key={0} value='M'>
                  Masculino
                </MenuItem>
                <MenuItem key={1} value='F'>
                  Femenino
                </MenuItem>
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField id="celular" label="# Celular" variant="outlined" style={{width:'100%'}}               
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                error={celularError}
                disabled
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField id="mail" label="E-Mail" variant="outlined" style={{width:'100%'}}               
                value={mail}
                onChange={(event) => setMail(event.target.value)}
                error={mailError}
                disabled
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-select-currency"
                select
                label="Estado"
                value={estado}
                style={{width: '100%'}}
                onChange={(event) => setEstado(event.target.value)}
                disabled
              >
                <MenuItem key={0} value={'B'}>
                  Baja
                </MenuItem>
                <MenuItem key={1} value={'A'}>
                  Activo
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}