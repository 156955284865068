import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function HomeCliente(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("0")) {                    
        navigate('/');
      }
    }
    props.setPagina('homeCliente'); cookies.set('sethmon_pagina', 'homeCliente');

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#ffffff', textAlign: 'center'}}>
           {"Bienvenid@ " + props.userArray.nombre}
          </Typography>
        </Grid>

        <Grid xs={12}>  
          <Button onClick={() => navigate('/ClienteRutina')} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
            Ver rutina
          </Button>
        </Grid>


           
      </Grid>

    </Box>
  );
}