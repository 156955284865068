import React, { useEffect } from 'react';
import axios from "axios";
import Cookies from 'universal-cookie';
import Grid from '@mui/material/Unstable_Grid2';
import { RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import imgLogo from "../img/logo 2.png";
import {useNavigate} from 'react-router-dom';

export default function Login(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();  
  const [usuario, setUsuario] = React.useState('');
  const [usuarioError, setUsuarioError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [tipo, setTipo] = React.useState(1);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === true){        
        navigate('/home');
      }
    } else {
      if(cookies.get('sethmon_login') === true){        
        navigate('/home');
      }
    }
  }, []);

  const handleClickShowPassword = () => {
    if(showPassword === false){
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function iniciarSesion(){      
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (usuario === "") { setUsuarioError('error'); ban = 1; } else { setUsuarioError(''); }
    if (password === "") { setPasswordError('error'); ban = 1; } else { setPasswordError(''); }

    if(ban === 0){
      axios.post(props.ruta + "backend/login.php", {
        usuario: usuario,
        password: password,
        tipo: tipo,
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            cookies.set('sethmon_tipo', data[0].tipo);
            props.setUserArray(data[0]); cookies.set('sethmon_userArray', data[0]);
            props.setLogin(true); cookies.set('sethmon_login', true);                        
            props.setDialogNotificacionOpen(false);    
            if(tipo === 1){
              navigate('/Home');
            } else if(tipo === 2 || tipo === '2') {
              navigate('/homeCliente');
            }

          } else if (data[0].error === 1) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('El usuario no existe');            
          } else if (data[0].error === 2) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('Conseña incorrecta');            
          } else if (data[0].error === 3) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('Su usuario ya no cuenta con permisos para ingresar al sistema, contacte al administrador');            
          } else if (data[0].error === 4) {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje('Cuenta con un pago pendiente que realizar, contacte al administrador');  
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al logearse, contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  const handleChange = (event) => {
    setTipo(event.target.value);
  }  
  
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: props.colorTres, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", margin: '-5%' }} >
      <Grid container spacing={2}  justifyContent="center" direction="column" alignItems="center">
        
        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <img
            src={imgLogo}
            alt="Logo"
            style={{ marginTop: "15%", width: "90%", maxWidth: "250px", marginBottom: "5%" }}
          />
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Typography
            variant="h2"
            style={{
              color: props.colorDos,
              fontWeight: "900",
              marginBottom: "2%"
            }}>
            SETHMON
          </Typography>
        </Grid>

        <Grid item xs={10} md={3}>
          <FormControl
            variant="outlined"
            style={{backgroundColor: '#ffffff', width:'100%'}}            
          >
            <InputLabel htmlFor="outlined-adornment-password" style={{ backgroundColor: '#ffffff', borderRadius: 5, color: '#000000'}}>
              Usuario
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-usuario"
              error={usuarioError}
              value={usuario}
              label='Contraseña'
              style={{width:'100%'}}
              onChange={(event) => setUsuario(event.target.value)}
              labelWidth={100}
            />
            </FormControl>
        </Grid>  

        <Grid item xs={10} md={3}>
          <FormControl
            variant="outlined"
            style={{backgroundColor: '#ffffff', width:'100%'}}            
          >
            <InputLabel htmlFor="outlined-adornment-password" style={{ backgroundColor: '#ffffff', borderRadius: 5, color: '#000000'}}>
              Contraseña
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              error={passwordError}
              value={password}
              label='Contraseña'
              style={{width:'100%'}}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? iniciarSesion() : "")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
        </Grid> 

        <Grid item xs={10} md={3}>
          <FormControl 
          style={{
            alignItems: 'center',
            color: '#ffffff',
            width: "100%",
          }}>
            <RadioGroup
              row
              name="radioTipo"
              value={tipo}
              onChange={handleChange}
            >
              <FormControlLabel value={1} control={<Radio sx={{color: '#ffffff', '&.Mui-checked': {color: '#ffffff'}}} />} label="Coach" />
              <FormControlLabel value={2} control={<Radio sx={{color: '#ffffff', '&.Mui-checked': {color: '#ffffff'}}} />} label="Cliente" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Button
            id="btnIniSe"
            variant="contained"
            size="large"
            style={{ 
              backgroundColor: props.colorDos,
              color: '#ffffff',
              fontWeight: "900",
              width: "50%",
              padding: "3%"
            }}
            onClick={iniciarSesion}>
            Iniciar sesión
          </Button>
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Typography
            variant="body1"
            style={{
              color: props.colorDos,
              fontWeight: "900",
              marginTop: '5%'
            }}>
            Versión {props.version}
          </Typography>
        </Grid>

        <Grid container xs={12} md={4} alignItems="center" justifyContent="center">  
          <Typography
            variant="caption"
            style={{
              color: props.colorDos,
              marginTop: '5%'
            }}>
            Creado por: KOI Systems
          </Typography>
        </Grid>

      </Grid>
            
    </Box>
  );
}