import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoEditar(props) {
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');
  const [app, setApp] = React.useState('');
  const [appError, setAppError] = React.useState('');
  const [apm, setApm] = React.useState('');
  const [apmError, setApmError] = React.useState('');
  const [memTipo, setMemTipo] = React.useState('1');
  const [memCant, setMemCant] = React.useState('');
  const [memCantError, setMemCantError] = React.useState('');
  const [memCosto, setMemCosto] = React.useState('');
  const [memCostoError, setMemCostoError] = React.useState('');
  const [celular, setCelular] = React.useState('');
  const [celularError, setCelularError] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    setNombre(props.arrayDatos.nombre);
    setApp(props.arrayDatos.aPaterno);
    setApm(props.arrayDatos.aMaterno);
    setCelular(props.arrayDatos.celular);
    setMemTipo(props.arrayDatos.mem_tipo);
    setMemCant(props.arrayDatos.mem_cant_cli);
    setMemCosto(props.arrayDatos.mem_costo); 
  }, []);

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (nombre === "") { setNombreError('error'); ban = 1; } else { setNombreError(''); }
    if (app === "") { setAppError('error'); ban = 1; } else { setAppError(''); }
    if (apm === "") { setApmError('error'); ban = 1; } else { setApmError(''); }
    if (memTipo === "") { ban = 1; }
    if (memCant === "") { setMemCantError('error'); ban = 1; } else { setMemCantError(''); }
    if (celular === "") { setCelularError('error'); ban = 1; } else { setCelularError(''); }
    if (memCosto === "") { setMemCostoError('error'); ban = 1; } else { setMemCostoError(''); }

    if(ban === 0){
      let resultado = parseFloat(memCant);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - memCant;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setMemCantError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setMemCantError('error');
        ban = 2;
      }

      let resultado2 = parseFloat(memCosto);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado2)){
        // console.log('numero')
        let a = resultado2 - memCosto;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setMemCostoError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setMemCostoError('error');
        ban = 2;
      }


      if(Number.isNaN(resultado) && resultado !== memCant){   
        console.log(!Number.isNaN(resultado));
      }

      
      if(Number.isNaN(resultado2) && resultado2 !== memCosto){   
        console.log(!Number.isNaN(resultado2));
      }
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/usuarios/editar.php", {
          userId: props.userArray.id,
          id: props.arrayDatos.id,
          nombre: nombre,
          app: app,
          apm: apm,
          memTipo: memTipo,
          memCosto: memCosto,
          memCant: memCant,
          celular: celular
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("El usuario se ha registrado con éxito");
            props.getDatos();
            props.setOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en los campos de "Costo patronal" y "Costo libre"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='md'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Editar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <TextField id="nombre" label="Nombre" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="app" label="A. Paterno" variant="outlined" style={{width:'100%'}}               
                value={app}
                onChange={(event) => setApp(event.target.value)}
                error={appError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="apm" label="A. Materno" variant="outlined" style={{width:'100%'}}               
                value={apm}
                onChange={(event) => setApm(event.target.value)}
                error={apmError}
              />
            </Grid>         

            <Grid item xs={12} md={3}>
              <TextField id="celular" label="# Celular" variant="outlined" style={{width:'100%'}}               
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                error={celularError}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Mem. Tipo"
                value={memTipo}
                style={{width: '100%'}}
                onChange={(event) => setMemTipo(event.target.value)}
              >
                <MenuItem key={0} value='1'>
                  Mensual
                </MenuItem>
                <MenuItem key={1} value='2'>
                  Bimestral
                </MenuItem>
                <MenuItem key={2} value='3'>
                  Trimestral
                </MenuItem>
                <MenuItem key={3} value='6'>
                  Semestral
                </MenuItem>
                <MenuItem key={4} value='12'>
                  Anual
                </MenuItem>
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="memcosto" label="Mem. Costo" variant="outlined" style={{width:'100%'}}               
                value={memCosto}
                onChange={(event) => setMemCosto(event.target.value)}
                error={memCostoError}
              />
            </Grid>            
            
            <Grid item xs={12} md={3}>
              <TextField id="memCant" label="Mem. Cantidad clientes" variant="outlined" style={{width:'100%'}}               
                value={memCant}
                onChange={(event) => setMemCant(event.target.value)}
                error={memCantError}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}