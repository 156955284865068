import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import axios from "axios";
import { CardContent, CardHeader, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoPorcentajes(props) { 
  const [tamDialog, setTamDialog] = React.useState('sm');
  const [banMenu, setBanMenu] = React.useState(0);  
  const [edad, setEdad] = React.useState('');
  const [peso, setPeso] = React.useState('');
  const [medida1, setMedida1] = React.useState('');
  const [medida2, setMedida2] = React.useState('');
  const [medida3, setMedida3] = React.useState('');
  const [medida4, setMedida4] = React.useState('');
  const [medida5, setMedida5] = React.useState('');
  const [medida6, setMedida6] = React.useState('');
  const [medida7, setMedida7] = React.useState('');  
  const [densidad, setDensidad] = React.useState(0);  
  const [porcentaje, setPorcentajes] = React.useState(0);  
  const [grasa, setGrasa] = React.useState(0); 
  const [masa, setMasa] = React.useState(0); 
  const [arrayDatosHistorico, setArrayDatosHistorico] = React.useState([]); 
  const [banVacio, setBanVacio] = React.useState(true); 

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
  }, []);  

  function cambiarPag(a){
    setBanMenu(a);

    if(a === 2){
      setTamDialog('xl');
      getDatosDw();
    }
  }

  function getDatosDw(){
    axios.post(props.ruta + "backend/clientes/get_datos_porcentaje_dw.php",{
      userId: props.userArray.id,      
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayDatosHistorico(data);
        setBanVacio(false);
      } else {
        setBanVacio(true);
      }
    })
  }

  const renderBotonesInicio = () => {
    if(banMenu === 0) {
      return(                
        <>
          <Grid item xs={12} md={2}>
            <Button onClick={() => cambiarPag(1)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
            Nuevo registro
            </Button>
          </Grid>
        </>
      )
    } else if(banMenu === 1) {
      return(                
        <>
          <Grid item xs={1}>
            <Tooltip title="Atras">
              <IconButton style={{color:'#000000'}}  onClick={() => cambiarPag(0)}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h6">
            Seleccione el método de medición
            </Typography>             
          </Grid>
          <Grid item xs={12} md={3}>
            <Button onClick={() => cambiarPag(2)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
            Durin y Womersley
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Button onClick={() => cambiarPag(3)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
            Jackson y Pollock (4)
            </Button>
          </Grid> */}
        </>
      )
    } else if(banMenu === 2) {
      return(        
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={1}>
            <Tooltip title="Atras">
              <IconButton style={{color:'#000000'}}  onClick={() => cambiarPag(1)}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Inicio">
              <IconButton style={{color:'#000000'}}  onClick={() => cambiarPag(0)}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h6">
            Método Durin y Womersley
            </Typography>             
          </Grid> 
          
          <Grid item xs={12} md={1}>
            <TextField label="Edad" variant="outlined" style={{width:'100%'}}               
              value={edad}
              onChange={(event) => setEdad(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField label="Peso (kg)" variant="outlined" style={{width:'100%'}}               
              value={peso}
              onChange={(event) => setPeso(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField label="Tríceps (mm)" variant="outlined" style={{width:'100%'}}               
              value={medida1}
              onChange={(event) => setMedida1(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField label="Abdomen (mm)" variant="outlined" style={{width:'100%'}}               
              value={medida2}
              onChange={(event) => setMedida2(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField label="Suprailiaco (mm)" variant="outlined" style={{width:'100%'}}               
              value={medida3}
              onChange={(event) => setMedida3(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField label="Bicep (mm)" variant="outlined" style={{width:'100%'}}               
              value={medida4}
              onChange={(event) => setMedida4(event.target.value)}
            />
          </Grid>          
          <Grid item xs={2} md={1}>
            <Button onClick={() => calcularDW()} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
            Calcular
            </Button>
          </Grid>     
          
          <Grid item xs={12} md={12}>            
            {renderResultadosDW()}  
          </Grid>  

          <Grid item xs={12} md={6}>            
            {renderResultadoDWHistorico()}  
          </Grid>  

          <Grid item xs={12} md={6}>            
            {/* {renderResultadoDWHistorico()}   */}
          </Grid>  
          
                       

        </Grid>
        
      )
    } else if(banMenu === 3) {
      return(                
        <>
          <Grid item xs={1}>
            <Tooltip title="Atras">
              <IconButton style={{color:'#000000'}}  onClick={() => cambiarPag(1)}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Inicio">
              <IconButton style={{color:'#000000'}}  onClick={() => cambiarPag(0)}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h6">
            Jackson y Pollock (4)
            </Typography>             
          </Grid> 
        </>
      )
    }
  } 

  function calcularDW(){           
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (peso === "") { ban = 1; } 
    if (edad === "") { ban = 1; } 
    if (medida1 === "") { ban = 1; } 
    if (medida2 === "") { ban = 1; } 
    if (medida3 === "") { ban = 1; } 
    if (medida4 === "") { ban = 1; } 

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/porcentaje_calcular_dw.php", {
          userId: props.userArray.id,
          idCliente: props.arrayDatos.id,
          peso: peso,
          edad: edad,
          medida1: medida1,
          medida2: medida2,
          medida3: medida3,
          medida4: medida4,
          sexo: props.arrayDatos.sexo
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            setDensidad(data[0].densidad);
            setPorcentajes(data[0].porcentaje);
            setGrasa(data[0].grasa);
            setMasa(data[0].masa);
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Los datos se han guardado con exito");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Monto $"');
    } 
  }  

  const renderResultadosDW = () => {
    if(densidad !== 0){
      return(
        <Grid container spacing={2} justifyContent={'center'}>

          <Grid item xs={6} md={3}>
            <Card>
              <CardHeader
                title="Densidad" 
                style={{backgroundColor: props.colorDos, textAlign: 'center'}}             
              />
              <CardContent>             
                <Typography variant="h3" textAlign={'center'}>
                {densidad} <span style={{fontSize:'0.3em'}}> g/cm3 </span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card>
              <CardHeader
                title="Porcentaje de grasa" 
                style={{backgroundColor: props.colorDos, textAlign: 'center'}}             
              />
              <CardContent>             
                <Typography variant="h3" textAlign={'center'}>
                {porcentaje} <span style={{fontSize:'0.3em'}}>%</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card>
              <CardHeader
                title="Masa grasa" 
                style={{backgroundColor: props.colorDos, textAlign: 'center'}}             
              />
              <CardContent>             
                <Typography variant="h3" textAlign={'center'}>
                {grasa} <span style={{fontSize:'0.3em'}}>kg</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card>
              <CardHeader
                title="Masa libre de grasa" 
                style={{backgroundColor: props.colorDos, textAlign: 'center'}}             
              />
              <CardContent>             
                <Typography variant="h3" textAlign={'center'}>
                {masa} <span style={{fontSize:'0.3em'}}>kg</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )
    }
  }

  

  const renderResultadoDWHistorico = () => {
    return(
      <div style={{backgroundColor: props.colorTres, border: '1px solid #000000', textAlign:' center'}}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{color: '#ffffff'}}>
          Medidas historico
          </Typography>       
        </Grid>

        <Grid  item xs={12}>
          <TableContainer>
              <Table
                sx={{ minWidth: '100%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Fecha
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Edad (años)
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Peso (kg)
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Trícep (mm)
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Abdomen (mm)
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Suprailiaco (mm)
                    </TableCell>
                    <TableCell align='center' style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '14.28%'}}>
                      Bicep (mm)
                    </TableCell>
                  </TableRow>
                </TableHead>
                {renderResultadoDWHistoricoTBody()}                
              </Table>
            </TableContainer>
        </Grid>

      </div>
    )
  }

  const renderResultadoDWHistoricoTBody = () =>{    
    if(banVacio === false){
      return(
        <TableBody>
          {arrayDatosHistorico.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{cursor: 'default', backgroundColor: row.estado === 'B'? '#ffcdd2':'#ffffff' }}
              >
                <TableCell align="center">{row.fecha}</TableCell>
                <TableCell align="center">{row.edad}</TableCell>
                <TableCell align="center">{row.peso}</TableCell>
                <TableCell align="center">{row.medida1}</TableCell>
                <TableCell align="center">{row.medida2}</TableCell>
                <TableCell align="center">{row.medida3}</TableCell>
                <TableCell align="center">{row.medida4}</TableCell>
              </TableRow>
            );
          })}                  
        </TableBody>
      )
    } else {
      return(         
        <TableRow
          hover
          tabIndex={-1}
          key={1}
          style={{cursor: 'default'}}
        >
          <TableCell align="center" colSpan={14}>No se tienen registros</TableCell>
        </TableRow>
      )
    }
  } 

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={tamDialog}
        fullWidth={true}
      >        
        <DialogTitle id="customized-dialog-title" sx={{ m:0, p:2}} style={{backgroundColor: '#082342', color: '#ffffff' }}>
        Porcentaje de grasa corporal
        </DialogTitle>        
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Grid container spacing={2} justifyContent={'center'}>

          {renderBotonesInicio()}

          {/* <Grid item xs={12}>
            {renderRutinaNueva()}
            {renderRutinaHistorico()}
            {renderRutinaHistoricoVer()}
          </Grid> */}
            
          </Grid>
        </DialogContent>
        {/* <DialogActions>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}