import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoRutinaEjercicioEliminar(props) {

  const handleClose = () => {
    props.setOpen(false)
  };  

  function eliminar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if(ban === 0){
      axios.post(props.ruta + "backend/rutina/ejercicio_eliminar.php", {
          userId: props.userArray.id,
          id: props.arrayDatos.id,
          ejercicio: props.arrayDatos.ejercicio,
          musculo: props.arrayDatos.musculo,
          sets: props.arrayDatos.sets,
          rep: props.arrayDatos.rep,
          metodo: props.arrayDatos.metodo,
          rer: props.arrayDatos.rer,
          descanso: props.arrayDatos.descanso
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.getDatosVol(props.idRutina);
            props.getDatosDiaEjercicios(props.dia, props.idRutina);
            props.setOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='xl'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#a31545', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            ¿Seguro que deseas eliminar este registro?
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={2}>
              <TextField label="Ejercicio" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.ejercicio}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label="Musculo" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.musculo}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField label="Sets" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.sets}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label="Repeticiones" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.rep}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label="Método de intensificación" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.metodo}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField label="RER" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.rer}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label="Descanso" variant="outlined" style={{width:'100%'}}               
                value={props.arrayDatos.descanso}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={eliminar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Eliminar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}