import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import RutinaNueva from './Rutina/RutinaNueva';
import RutinaHistorico from './Rutina/RutinaHistorico';
import RutinaHistoricoVer from './Rutina/RutinaHistoricoVer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoRutina(props) {
  const [tamDialog, setTamDialog] = React.useState('sm');
  const [banRutinaNueva, setBanRutinaNueva] = React.useState(false);
  const [banRutinaHistorico, setBanRutinaHistorico] = React.useState(false);
  const [banRutinaHistoricoVer, setBanRutinaHistoricoVer] = React.useState(false);
  const [idRutina, setIdRutina] = React.useState(0);

  const handleClose = () => {
    props.setOpen(false)
  };

  const cambiarPag = (a) =>{
    if(a === 'rutinaNueva'){
      setTamDialog('xl');
      setBanRutinaNueva(true);
      setBanRutinaHistorico(false);
      setBanRutinaHistoricoVer(false);
    } else if(a === 'rutinaHistorico'){
      setTamDialog('sm');
      setBanRutinaNueva(false);
      setBanRutinaHistorico(true);
      setBanRutinaHistoricoVer(false);
    } else if(a === 'rutinaHistoricoVer'){
      setTamDialog('xl');
      setBanRutinaNueva(false);
      setBanRutinaHistorico(false);
      setBanRutinaHistoricoVer(true);
    }else {
      setTamDialog('sm');
      setBanRutinaNueva(false);
      setBanRutinaHistorico(false);
      setBanRutinaHistoricoVer(false);
    }

  }  

  const renderBotonesInicio = () => {
    if(!banRutinaNueva && !banRutinaHistorico && !banRutinaHistoricoVer) {
      return(                
        <>
          <Grid item xs={12} md={4}>
            <Button onClick={() => cambiarPag('rutinaNueva')} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
            Crear nueva rutina
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button onClick={() => cambiarPag('rutinaHistorico')} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
            Ver historial de rutinas
            </Button>
          </Grid>
        </>
      )
    }
  }

  const renderRutinaNueva = () => {
    if(banRutinaNueva) {      
      return(                
        <RutinaNueva
          {...props}
          cambiarPag = {cambiarPag}
        />
      )
    }
  }

  const renderRutinaHistorico = () => {
    if(banRutinaHistorico) {      
      return(                
        <RutinaHistorico
          {...props}
          cambiarPag = {cambiarPag}
          setIdRutina = {setIdRutina}
        />
      )
    }
  }

  const renderRutinaHistoricoVer = () => {
    if(banRutinaHistoricoVer) {      
      return(                
        <RutinaHistoricoVer
          {...props}
          cambiarPag = {cambiarPag}
          idRutina = {idRutina}
          setIdRutina = {setIdRutina}
        />
      )
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={tamDialog}
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Rutina de {props.arrayDatos.nombre + " " + props.arrayDatos.aPaterno + " " + props.arrayDatos.aMaterno }
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>

            {renderBotonesInicio()}

            <Grid item xs={12}>
              {renderRutinaNueva()}
              {renderRutinaHistorico()}
              {renderRutinaHistoricoVer()}
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}