import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false)
  };  

  const handleClickShowPassword = () => {
    if(showPassword === false){
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function cambiarPassword(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (password === "") { setPasswordError('error'); ban = 1; } else { setPasswordError(''); }

    if(ban === 0){
      axios.post(props.ruta + "backend/usuarios/reset_password.php", {
          userId: props.userArray.id,
          id: props.userArray.id,
          password: password,
          tipo: props.userArray.tipo
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("La contraseña fue actualiza con éxito y se solicitara en el próximo inicio de sesión");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='lg'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Usuario
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField id="nombre" label="Nombre" variant="outlined" style={{width:'100%'}}               
                value={props.userArray.nombre}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="app" label="A. Paterno" variant="outlined" style={{width:'100%'}}               
                value={props.userArray.app}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="apm" label="A. Materno" variant="outlined" style={{width:'100%'}}               
                value={props.userArray.apm}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="usuario" label="Usuario" variant="outlined" style={{width:'100%'}}               
                value={props.userArray.usuario}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                variant="outlined"
                style={{backgroundColor: '#ffffff', width:'100%'}}            
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  error={passwordError}
                  value={password}
                  label='Contraseña'
                  style={{width:'100%'}}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Button onClick={cambiarPassword} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
                Cambiar contraseña
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}