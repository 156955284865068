import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoDetVol(props) {
  const [ayuda, setAyuda] = React.useState('');
  const [sexoVme, setSexoVme] = React.useState('Hombre');
  const [pesoVme, setPesoVme] = React.useState('S. Pesado');
  const [tallaVme, setTallaVme] = React.useState('Muy alto');
  const [nivelVme, setNivelVme] = React.useState('Principiante');
  const [edadVme, setEdadVme] = React.useState('<19');
  const [nutriVme, setNutriVme] = React.useState('Buena');
  const [suenVme, setSuenVme] = React.useState('Deficiente');
  const [estresVme, setEstresVme] = React.useState('Bajo');
  const [volVme, setVolVme] = React.useState('1');
  const [histVme, setHistVme] = React.useState('1');
  const [finalVme, setFinalVme] = React.useState('Sin asignar');

  const [sexoVmr, setSexoVmr] = React.useState('Hombre');
  const [pesoVmr, setPesoVmr] = React.useState('S. Pesado');
  const [tallaVmr, setTallaVmr] = React.useState('Muy alto');
  const [nivelVmr, setNivelVmr] = React.useState('Principiante');
  const [edadVmr, setEdadVmr] = React.useState('<19');
  const [nutriVmr, setNutriVmr] = React.useState('Buena');
  const [suenVmr, setSuenVmr] = React.useState('Deficiente');
  const [estresVmr, setEstresVmr] = React.useState('Bajo');
  const [volVmr, setVolVmr] = React.useState('1');
  const [histVmr, setHistVmr] = React.useState('1');
  const [finalVmr, setFinalVmr] = React.useState('Sin asignar');
  
  const [pectoral, setPectoral] = React.useState('0');  
  const [pectoralError, setPectoralError] = React.useState('');
  const [pectoralVm, setPectoralVm] = React.useState('4');
  const [pectoralVme, setPectoralVme] = React.useState('6');
  const [pectoralVmr, setPectoralVmr] = React.useState('20');

  const [espalda, setEspalda] = React.useState('0');  
  const [espaldaError, setEspaldaError] = React.useState('');
  const [espaldaVm, setEspaldaVm] = React.useState('6');
  const [espaldaVme, setEspaldaVme] = React.useState('6');
  const [espaldaVmr, setEspaldaVmr] = React.useState('20');
  
  const [biceps, setBiceps] = React.useState('0');  
  const [bicepsError, setBicepsError] = React.useState('');
  const [bicepsVm, setBicepsVm] = React.useState('4');
  const [bicepsVme, setBicepsVme] = React.useState('6');
  const [bicepsVmr, setBicepsVmr] = React.useState('20');
  
  const [triceps, setTriceps] = React.useState('0');  
  const [tricepsError, setTricepsError] = React.useState('');
  const [tricepsVm, setTricepsVm] = React.useState('4');
  const [tricepsVme, setTricepsVme] = React.useState('6');
  const [tricepsVmr, setTricepsVmr] = React.useState('20');
  
  const [delLP, setDelLP] = React.useState('0');  
  const [delLPError, setDelLPError] = React.useState('');
  const [delLPVm, setDelLPVm] = React.useState('6');
  const [delLPVme, setDelLPVme] = React.useState('6');
  const [delLPVmr, setDelLPVmr] = React.useState('20');
  
  const [delA, setDelA] = React.useState('0');  
  const [delAError, setDelAError] = React.useState('');
  const [delAVm, setDelAVm] = React.useState('2');
  const [delAVme, setDelAVme] = React.useState('6');
  const [delAVmr, setDelAVmr] = React.useState('20');
  
  const [trapecio, setTrapecio] = React.useState('0');  
  const [trapecioError, setTrapecioError] = React.useState('');
  const [trapecioVm, setTrapecioVm] = React.useState('2');
  const [trapecioVme, setTrapecioVme] = React.useState('6');
  const [trapecioVmr, setTrapecioVmr] = React.useState('20');
  
  const [abdomen, setAbdomen] = React.useState('0');  
  const [abdomenError, setAbdomenError] = React.useState('');
  const [abdomenVm, setAbdomenVm] = React.useState('2');
  const [abdomenVme, setAbdomenVme] = React.useState('6');
  const [abdomenVmr, setAbdomenVmr] = React.useState('20');
  
  const [gluteo, setGluteo] = React.useState('0');  
  const [gluteoError, setGluteoError] = React.useState('');
  const [gluteoVm, setGVm] = React.useState('2');
  const [gluteoVme, setGluteoVme] = React.useState('6');
  const [gluteoVmr, setGluteoVmr] = React.useState('20');
  
  const [cuadriceps, setCuadriceps] = React.useState('0');  
  const [cuadricepsError, setCuadricepsError] = React.useState('');
  const [cuadricepsVm, setCuadricepsVm] = React.useState('6');
  const [cuadricepsVme, setCuadricepsVme] = React.useState('6');
  const [cuadricepsVmr, setCuadricepsVmr] = React.useState('20');
  
  const [isquio, setIsquio] = React.useState('0');  
  const [isquioError, setIsquioError] = React.useState('');
  const [isquioVm, setIsquioVm] = React.useState('3');
  const [isquioVme, setIsquioVme] = React.useState('6');
  const [isquioVmr, setIsquioVmr] = React.useState('20');
  
  const [tricepSural, setTricepSural] = React.useState('0');  
  const [tricepSuralError, setTricepSuralError] = React.useState('');
  const [tricepSuralVm, setTricepSuralVm] = React.useState('6');
  const [tricepSuralVme, setTricepSuralVme] = React.useState('6');
  const [tricepSuralVmr, setTricepSuralVmr] = React.useState('20');
  
  const [adductores, setAdductores] = React.useState('0');  
  const [adductoresError, setAdductoresError] = React.useState('');
  const [adductoresVm, setAdductoresVm] = React.useState('3');
  const [adductoresVme, setAdductoresVme] = React.useState('6');
  const [adductoresVmr, setAdductoresVmr] = React.useState('20');

  useEffect(() => {    
    getDatosVme();
    getDatosVmr();
    getDatosVol();
  }, [props.userArray.id]);

  function getDatosVme(){
    axios.post(props.ruta + "backend/clientes/get_datos_vme.php",{
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setSexoVme(data[0].sexo);
        setPesoVme(data[0].peso);
        setTallaVme(data[0].talla);
        setNivelVme(data[0].nivel);
        setEdadVme(data[0].edad);
        setNutriVme(data[0].nutricion);
        setSuenVme(data[0].suen);
        setEstresVme(data[0].estres);
        setVolVme(data[0].vol);
        setHistVme(data[0].hist);
        setFinalVme(data[0].final);        
        setPectoralVme(data[0].pectoral);
        setEspaldaVme(data[0].espalda);
        setBicepsVme(data[0].biceps);
        setTricepsVme(data[0].triceps);
        setDelLPVme(data[0].delLP);
        setDelAVme(data[0].delA);
        setTrapecioVme(data[0].trapecio);
        setAbdomenVme(data[0].abdomen);
        setGluteoVme(data[0].gluteo);
        setCuadricepsVme(data[0].cuadriceps);
        setIsquioVme(data[0].isquio);
        setTricepSuralVme(data[0].tricepSural);
        setAdductoresVme(data[0].adductores);
      }
    })
  }

  function getDatosVmr(){
    axios.post(props.ruta + "backend/clientes/get_datos_vmr.php",{
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setSexoVmr(data[0].sexo);
        setPesoVmr(data[0].peso);
        setTallaVmr(data[0].talla);
        setNivelVmr(data[0].nivel);
        setEdadVmr(data[0].edad);
        setNutriVmr(data[0].nutricion);
        setSuenVmr(data[0].suen);
        setEstresVmr(data[0].estres);
        setVolVmr(data[0].vol);
        setHistVmr(data[0].hist);
        setFinalVmr(data[0].final);       
        setPectoralVmr(data[0].pectoral);
        setEspaldaVmr(data[0].espalda);
        setBicepsVmr(data[0].biceps);
        setTricepsVmr(data[0].triceps);
        setDelLPVmr(data[0].delLP);
        setDelAVmr(data[0].delA);
        setTrapecioVmr(data[0].trapecio);
        setAbdomenVmr(data[0].abdomen);
        setGluteoVmr(data[0].gluteo);
        setCuadricepsVmr(data[0].cuadriceps);
        setIsquioVmr(data[0].isquio);
        setTricepSuralVmr(data[0].tricepSural);
        setAdductoresVmr(data[0].adductores);

      }
    })
  }

  function getDatosVol(){
    axios.post(props.ruta + "backend/clientes/get_datos_vol.php",{
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        setPectoral(data[0].pectoral);
        setEspalda(data[0].espalda);
        setBiceps(data[0].biceps);
        setTriceps(data[0].triceps);
        setDelLP(data[0].delLP);
        setDelA(data[0].delA);
        setTrapecio(data[0].trapecio);
        setAbdomen(data[0].abdomen);
        setGluteo(data[0].gluteo);
        setCuadriceps(data[0].cuadriceps);
        setIsquio(data[0].isquio);
        setTricepSural(data[0].tricepSural);
        setAdductores(data[0].adductores);

      }
    })
  }

  const handleClose = () => {
    props.setOpen(false)
  }; 

  const renderAyuda = () => {
    if(ayuda === 'peso'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Peso corporal
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Mujeres
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Hombres
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Ligero'}</TableCell>
                    <TableCell align="center">{'<57 kg'}</TableCell>
                    <TableCell align="center">{'<75 kg'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Moderado'}</TableCell>
                    <TableCell align="center">{'57 a 75 kg'}</TableCell>
                    <TableCell align="center">{'75 a 100 kg'}</TableCell>
                  </TableRow>    
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Pesado'}</TableCell>
                    <TableCell align="center">{'75 a 90 kg'}</TableCell>
                    <TableCell align="center">{'100 a 125 kg'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Super pesado'}</TableCell>
                    <TableCell align="center">{'>90 kg'}</TableCell>
                    <TableCell align="center">{'>125 kg'}</TableCell>
                  </TableRow>         
                </TableBody>
                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    } else if(ayuda === 'talla'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Talla
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Mujeres
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '33.33%'}}
                    >
                      Hombres
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Baja'}</TableCell>
                    <TableCell align="center">{'<160 cm'}</TableCell>
                    <TableCell align="center">{'<170 cm'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Media'}</TableCell>
                    <TableCell align="center">{'160 a 167 cm'}</TableCell>
                    <TableCell align="center">{'170 a 182 cm'}</TableCell>
                  </TableRow>    
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Alta'}</TableCell>
                    <TableCell align="center">{'168 a 175 cm'}</TableCell>
                    <TableCell align="center">{'183 a 195 cm'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Muy alta'}</TableCell>
                    <TableCell align="center">{'>175 cm'}</TableCell>
                    <TableCell align="center">{'>195 cm'}</TableCell>
                  </TableRow>         
                </TableBody>
                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    } else if(ayuda === 'sueño'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Sueño
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '50%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '50%'}}
                    >
                      Descripción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Deficiente'}</TableCell>
                    <TableCell align="center">{'<5 horas de sueño'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Moderado'}</TableCell>
                    <TableCell align="center">{'5 a 7 horas de sueño'}</TableCell>
                  </TableRow>    
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Bueno'}</TableCell>
                    <TableCell align="center">{'>7 horas de sueño'}</TableCell>
                  </TableRow>       
                </TableBody>
                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    } else if(ayuda === 'estres'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Estrés físico no relacionado al entrenamiento
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '25%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '75%'}}
                    >
                      Descripción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Alto'}</TableCell>
                    <TableCell align="center">{'Podría estar relacionado con el entrenamiento de otras actividades deportivas junto con el entrenamiento de fuerza, o bien trabajos físicamente demandantes.'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Moderado'}</TableCell>
                    <TableCell align="center">{'Es posible que tenga otros pasatiempos físicamente exigentes en los que participe 1 a 2 veces por semana y tenga una combinación de días de bajo y alto estrés en su trabajo'}</TableCell>
                  </TableRow>    
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'Bajo'}</TableCell>
                    <TableCell align="center">{'Tiene un trabajo que no es físicamente exigente y el entrenamiento de fuerza es su principal actividad física.'}</TableCell>
                  </TableRow>       
                </TableBody>
                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    } else if(ayuda === 'vol'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Volumen de trabajo histórico
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '10%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '90%'}}
                    >
                      Descripción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'1'}</TableCell>
                    <TableCell align="center">{'Frecuencia de 1 x/semana de cada grupo muscular o levantamiento con un trabajo mínimo de ejercicios accesorios.'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'2'}</TableCell>
                    <TableCell align="center">{'Frecuencia de 1 x/semana de cada grupo muscular o levantamiento con un trabajo mínimo de ejercicios accesorios.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'3'}</TableCell>
                    <TableCell align="center">{'Frecuencia de 2 x/semana de cada grupo muscular o levantamiento con un trabajo significativo de ejercicios accesorios.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'4'}</TableCell>
                    <TableCell align="center">{'Frecuencia de 2 a 3 x/semana de cada grupo muscular o levantamiento con un trabajo moderado a significativo de ejercicios accesorios.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'5'}</TableCell>
                    <TableCell align="center">{'Frecuencia de 3 x/semana o más de cada grupo muscular o levantamiento con un trabajo moderado a significativo de ejercicios accesorios.'}</TableCell>
                  </TableRow>       
                </TableBody>                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    } else if(ayuda === 'hab'){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              Habilidad de recuperación histórica
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '10%'}}
                    >
                      Clasificación
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', width: '90%'}}
                    >
                      Descripción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'1'}</TableCell>
                    <TableCell align="center">{'Presenta dolor muscular hasta 3 días después del entrenamiento y tiene dificultades para incrementar la carga de trabajo.'}</TableCell>
                  </TableRow>  
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'2'}</TableCell>
                    <TableCell align="center">{'Presenta dolor muscular hasta 2 días después del entrenamiento.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'3'}</TableCell>
                    <TableCell align="center">{'Presenta dolor muscular de 1 a 2 días después del entrenamiento y responde a cargas de trabajo promedio.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'4'}</TableCell>
                    <TableCell align="center">{'Rara vez presenta dolor muscular por más de 1 día después del entrenamiento y puede incrementar las cargas de trabajo.'}</TableCell>
                  </TableRow>
                  <TableRow hover style={{cursor: 'default'}} >
                    <TableCell align="center">{'5'}</TableCell>
                    <TableCell align="center">{'Rara vez presenta dolor muscular por más de 1 día después del entrenamiento, responde a cargas de trabajo altas y posiblemente tenga experiencia en deportes de resistencia o CrossFit.'}</TableCell>
                  </TableRow>       
                </TableBody>                              
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>
      )
    }
  } 

  function guardarVme(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/guardar_vme.php", {
          userId: props.userArray.id,
          idCliente: props.arrayDatos.id,
          sexoVme: sexoVme,
          pesoVme: pesoVme,
          tallaVme: tallaVme,
          nivelVme: nivelVme,
          edadVme: edadVme,
          nutriVme: nutriVme,
          suenVme: suenVme,
          estresVme: estresVme,
          volVme: volVme,
          histVme: histVme,
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {      
            getDatosVme();     
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Se ha guardado correctamente");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  function guardarVmr(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/guardar_vmr.php", {
          userId: props.userArray.id,
          idCliente: props.arrayDatos.id,
          sexoVmr: sexoVmr,
          pesoVmr: pesoVmr,
          tallaVmr: tallaVmr,
          nivelVmr: nivelVmr,
          edadVmr: edadVmr,
          nutriVmr: nutriVmr,
          suenVmr: suenVmr,
          estresVmr: estresVmr,
          volVmr: volVmr,
          histVmr: histVmr,
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) { 
            getDatosVmr();          
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Se ha guardado correctamente");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  function guardarVol(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (pectoral === "") { setPectoralError('error'); ban = 1; } else { setPectoralError(''); }
    if (espalda === "") { setEspaldaError('error'); ban = 1; } else { setEspaldaError(''); }
    if (biceps === "") { setBicepsError('error'); ban = 1; } else { setBicepsError(''); }
    if (triceps === "") { setTricepsError('error'); ban = 1; } else { setTricepsError(''); }
    if (delLP === "") { setDelLPError('error'); ban = 1; } else { setDelLPError(''); }
    if (delA === "") { setDelAError('error'); ban = 1; } else { setDelAError(''); }
    if (trapecio === "") { setTrapecioError('error'); ban = 1; } else { setTrapecioError(''); }
    if (abdomen === "") { setAbdomenError('error'); ban = 1; } else { setAbdomenError(''); }
    if (gluteo === "") { setGluteoError('error'); ban = 1; } else { setGluteoError(''); }
    if (cuadriceps === "") { setCuadricepsError('error'); ban = 1; } else { setCuadricepsError(''); }
    if (isquio === "") { setIsquioError('error'); ban = 1; } else { setIsquioError(''); }
    if (tricepSural === "") { setTricepSuralError('error'); ban = 1; } else { setTricepSuralError(''); }
    if (adductores === "") { setAdductoresError('error'); ban = 1; } else { setAdductoresError(''); }

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/guardar_vol.php", {
          userId: props.userArray.id,
          idCliente: props.arrayDatos.id,
          pectoral: pectoral,
          espalda: espalda,
          biceps: biceps,
          triceps: triceps,
          delLP: delLP,
          delA: delA,
          trapecio: trapecio,
          abdomen: abdomen,
          gluteo: gluteo,
          cuadriceps: cuadriceps,
          isquio: isquio,
          tricepSural: tricepSural,
          adductores: adductores,
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) { 
            getDatosVmr();          
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Se ha guardado correctamente");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='xl'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Determinación de volumen de {props.arrayDatos.nombre + " " + props.arrayDatos.aPaterno + " " + props.arrayDatos.aMaterno }
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={11} md={11}>
              <Typography variant="h6" style={{textAlign: 'center'}}>
                Botones de ayuda
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Button onClick={() => setAyuda('')} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
                X
              </Button>
            </Grid>
            
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('peso')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Peso corporal
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('talla')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Talla
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('sueño')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Sueño
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('estres')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Estrés Físico
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('vol')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Volumen de trabajo
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => setAyuda('hab')} variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', minWidth:'100%', minHeight: '100%'}}>
                Habilidad de recuperación
              </Button>
            </Grid>

            <Grid item xs={12}>
              {renderAyuda()}
            </Grid>

            <Grid item xs={12} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              <Typography variant="h6" style={{textAlign: 'center'}}>
                Ajuste al VME
              </Typography>
            </Grid>          
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sexo"
                value={sexoVme}
                style={{width: '100%'}}
                onChange={(event) => setSexoVme(event.target.value)}
              >
                <MenuItem key={0} value='Hombre'>
                Hombre
                </MenuItem>
                <MenuItem key={1} value='Mujer'>
                Mujer
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Peso"
                value={pesoVme}
                style={{width: '100%'}}
                onChange={(event) => setPesoVme(event.target.value)}
              >
                <MenuItem key={0} value='S. Pesado'>
                S. Pesado
                </MenuItem>
                <MenuItem key={1} value='Pesado'>
                Pesado
                </MenuItem>
                <MenuItem key={2} value='Medio'>
                Medio
                </MenuItem>
                <MenuItem key={3} value='Ligero'>
                Ligero
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Talla"
                value={tallaVme}
                style={{width: '100%'}}
                onChange={(event) => setTallaVme(event.target.value)}
              >
                <MenuItem key={0} value='Muy alto'>
                Muy alto
                </MenuItem>
                <MenuItem key={1} value='Alto'>
                Alto
                </MenuItem>
                <MenuItem key={2} value='Media'>
                Media
                </MenuItem>
                <MenuItem key={3} value='Bajo'>
                Bajo
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Nivel de alteta"
                value={nivelVme}
                style={{width: '100%'}}
                onChange={(event) => setNivelVme(event.target.value)}
              >
                <MenuItem key={0} value='Principiante'>
                Principiante
                </MenuItem>
                <MenuItem key={1} value='Intermedio'>
                Intermedio
                </MenuItem>
                <MenuItem key={2} value='Avanzado'>
                Avanzado
                </MenuItem>
                <MenuItem key={3} value='Muy avanzado'>
                Muy avanzado
                </MenuItem>
              </TextField>
            </Grid>            
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Edad"
                value={edadVme}
                style={{width: '100%'}}
                onChange={(event) => setEdadVme(event.target.value)}
              >
                <MenuItem key={0} value='<19'>
                {'<19'}
                </MenuItem>
                <MenuItem key={1} value='20s'>
                20s
                </MenuItem>
                <MenuItem key={2} value='30s'>
                30s
                </MenuItem>
                <MenuItem key={3} value='40s'>
                40s
                </MenuItem>
                <MenuItem key={4} value='50s'>
                50s
                </MenuItem>
              </TextField>
            </Grid>            
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Nutrición"
                value={nutriVme}
                style={{width: '100%'}}
                onChange={(event) => setNutriVme(event.target.value)}
              >
                <MenuItem key={0} value='Buena'>
                Buena
                </MenuItem>
                <MenuItem key={1} value='Promedio'>
                Promedio
                </MenuItem>
                <MenuItem key={2} value='Deficiente'>
                Deficiente
                </MenuItem>
              </TextField>
            </Grid>        
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sueño"
                value={suenVme}
                style={{width: '100%'}}
                onChange={(event) => setSuenVme(event.target.value)}
              >
                <MenuItem key={0} value='Deficiente'>
                Deficiente
                </MenuItem>
                <MenuItem key={1} value='Promedio'>
                Promedio
                </MenuItem>
                <MenuItem key={2} value='Moderado'>
                Moderado
                </MenuItem>
              </TextField>
            </Grid>    
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Estrés físico"
                value={estresVme}
                style={{width: '100%'}}
                onChange={(event) => setEstresVme(event.target.value)}
              >
                <MenuItem key={0} value='Alto'>
                Alto
                </MenuItem>
                <MenuItem key={1} value='Moderado'>
                Moderado
                </MenuItem>
                <MenuItem key={2} value='Bajo'>
                Bajo
                </MenuItem>
              </TextField>
            </Grid>                
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Volumen histórico"
                value={volVme}
                style={{width: '100%'}}
                onChange={(event) => setVolVme(event.target.value)}
              >
                <MenuItem key={0} value='1'>
                1
                </MenuItem>
                <MenuItem key={1} value='2'>
                2
                </MenuItem>
                <MenuItem key={2} value='3'>
                3
                </MenuItem>
                <MenuItem key={3} value='4'>
                4
                </MenuItem>
                <MenuItem key={4} value='5'>
                5
                </MenuItem>
              </TextField>
            </Grid>                  
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Recuperación histórica"
                value={histVme}
                style={{width: '100%'}}
                onChange={(event) => setHistVme(event.target.value)}
              >
                <MenuItem key={0} value='1'>
                1
                </MenuItem>
                <MenuItem key={1} value='2'>
                2
                </MenuItem>
                <MenuItem key={2} value='3'>
                3
                </MenuItem>
                <MenuItem key={3} value='4'>
                4
                </MenuItem>
                <MenuItem key={4} value='5'>
                5
                </MenuItem>
              </TextField>
            </Grid>            
            <Grid item xs={12} md={2}>
              <Button onClick={() => guardarVme()} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
                Guardar
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField id="final" label="Final" disabled variant="outlined" style={{width:'100%'}}               
                value={finalVme}
              />
            </Grid>

            {/* ------------------------ VMR ------------------------------------- */}


            <Grid item xs={12} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              <Typography variant="h6" style={{textAlign: 'center'}}>
                Ajuste al VMR
              </Typography>
            </Grid>          
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sexo"
                value={sexoVmr}
                style={{width: '100%'}}
                onChange={(event) => setSexoVmr(event.target.value)}
              >
                <MenuItem key={0} value='Hombre'>
                Hombre
                </MenuItem>
                <MenuItem key={1} value='Mujer'>
                Mujer
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Peso"
                value={pesoVmr}
                style={{width: '100%'}}
                onChange={(event) => setPesoVmr(event.target.value)}
              >
                <MenuItem key={0} value='S. Pesado'>
                S. Pesado
                </MenuItem>
                <MenuItem key={1} value='Pesado'>
                Pesado
                </MenuItem>
                <MenuItem key={2} value='Medio'>
                Medio
                </MenuItem>
                <MenuItem key={3} value='Ligero'>
                Ligero
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Talla"
                value={tallaVmr}
                style={{width: '100%'}}
                onChange={(event) => setTallaVmr(event.target.value)}
              >
                <MenuItem key={0} value='Muy alto'>
                Muy alto
                </MenuItem>
                <MenuItem key={1} value='Alto'>
                Alto
                </MenuItem>
                <MenuItem key={2} value='Media'>
                Media
                </MenuItem>
                <MenuItem key={3} value='Bajo'>
                Bajo
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Nivel de alteta"
                value={nivelVmr}
                style={{width: '100%'}}
                onChange={(event) => setNivelVmr(event.target.value)}
              >
                <MenuItem key={0} value='Muy avanzado'>
                Muy avanzado
                </MenuItem>
                <MenuItem key={1} value='Avanzado'>
                Avanzado
                </MenuItem>
                <MenuItem key={2} value='Principiante'>
                Principiante
                </MenuItem>
                <MenuItem key={3} value='Intermedio'>
                Intermedio
                </MenuItem>
              </TextField>
            </Grid>            
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Edad"
                value={edadVmr}
                style={{width: '100%'}}
                onChange={(event) => setEdadVmr(event.target.value)}
              >
                <MenuItem key={0} value='>50'>
                {'>50'}
                </MenuItem>
                <MenuItem key={1} value='40s'>
                40s
                </MenuItem>
                <MenuItem key={2} value='30s'>
                30s
                </MenuItem>
                <MenuItem key={3} value='20s'>
                20s
                </MenuItem>
                <MenuItem key={4} value='<19'>
                {'<19'}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Nutrición"
                value={nutriVmr}
                style={{width: '100%'}}
                onChange={(event) => setNutriVmr(event.target.value)}
              >
                <MenuItem key={0} value='Buena'>
                Buena
                </MenuItem>
                <MenuItem key={1} value='Promedio'>
                Promedio
                </MenuItem>
                <MenuItem key={2} value='Deficiente'>
                Deficiente
                </MenuItem>
              </TextField>
            </Grid>           
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sueño"
                value={suenVmr}
                style={{width: '100%'}}
                onChange={(event) => setSuenVmr(event.target.value)}
              >
                <MenuItem key={0} value='Deficiente'>
                Deficiente
                </MenuItem>
                <MenuItem key={1} value='Promedio'>
                Promedio
                </MenuItem>
                <MenuItem key={2} value='Moderado'>
                Moderado
                </MenuItem>
              </TextField>
            </Grid>  
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Estrés físico"
                value={estresVmr}
                style={{width: '100%'}}
                onChange={(event) => setEstresVmr(event.target.value)}
              >
                <MenuItem key={0} value='Alto'>
                Alto
                </MenuItem>
                <MenuItem key={1} value='Moderado'>
                Moderado
                </MenuItem>
                <MenuItem key={2} value='Bajo'>
                Bajo
                </MenuItem>
              </TextField>
            </Grid>               
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Volumen histórico"
                value={volVmr}
                style={{width: '100%'}}
                onChange={(event) => setVolVmr(event.target.value)}
              >
                <MenuItem key={0} value='1'>
                1
                </MenuItem>
                <MenuItem key={1} value='2'>
                2
                </MenuItem>
                <MenuItem key={2} value='3'>
                3
                </MenuItem>
                <MenuItem key={3} value='4'>
                4
                </MenuItem>
                <MenuItem key={4} value='5'>
                5
                </MenuItem>
              </TextField>
            </Grid>                  
            <Grid item xs={12} md={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="Recuperación histórica"
                value={histVmr}
                style={{width: '100%'}}
                onChange={(event) => setHistVmr(event.target.value)}
              >
                <MenuItem key={0} value='1'>
                1
                </MenuItem>
                <MenuItem key={1} value='2'>
                2
                </MenuItem>
                <MenuItem key={2} value='3'>
                3
                </MenuItem>
                <MenuItem key={3} value='4'>
                4
                </MenuItem>
                <MenuItem key={4} value='5'>
                5
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => guardarVmr()} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%'}}>
                Guardar
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField id="final" label="Final" disabled variant="outlined" style={{width:'100%'}}               
                value={finalVmr}
              />
            </Grid>

            {/* -------------------------------- GRUPO MUSCULAR ------------------------ */}

            <Grid item xs={12} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
              <Typography variant="h6" style={{textAlign: 'center'}}>
                Volúmenes teóricos de entrenamiento
              </Typography>
            </Grid>

            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Pectoral
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={pectoralVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={pectoralVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={pectoralVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={10}
                  onChange={(event) => setPectoral(event.target.value)}
                  error={pectoralError}             
                  value={pectoral}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Espalda
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={espaldaVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={espaldaVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={espaldaVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}}  tabIndex={11} 
                  onChange={(event) => setEspalda(event.target.value)}
                  error={espaldaError}             
                  value={espalda}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Bíceps
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={bicepsVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={bicepsVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={bicepsVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={12}  
                  onChange={(event) => setBiceps(event.target.value)}
                  error={bicepsError}             
                  value={biceps}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Tríceps
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={tricepsVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={tricepsVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={tricepsVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={13}  
                  onChange={(event) => setTriceps(event.target.value)}
                  error={tricepsError}             
                  value={triceps}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Deltoides (Lat/Post)
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={delLPVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={delLPVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={delLPVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={14}  
                  onChange={(event) => setDelLP(event.target.value)}
                  error={delLPError}             
                  value={delLP}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Deltoides Anterior
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={delAVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={delAVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={delAVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={15}  
                  onChange={(event) => setDelA(event.target.value)}
                  error={delAError}             
                  value={delA}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Trapecio
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={trapecioVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={trapecioVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={trapecioVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={16}
                  onChange={(event) => setTrapecio(event.target.value)}
                  error={trapecioError}             
                  value={trapecio}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Abdomen
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={abdomenVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={abdomenVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={abdomenVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={17}  
                  onChange={(event) => setAbdomen(event.target.value)}
                  error={abdomenError}             
                  value={abdomen}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Glúteo
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={gluteoVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={gluteoVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={gluteoVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={18}  
                  onChange={(event) => setGluteo(event.target.value)}
                  error={gluteoError}             
                  value={gluteo}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Cuádriceps
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={cuadricepsVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={cuadricepsVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={cuadricepsVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={19}  
                  onChange={(event) => setCuadriceps(event.target.value)}
                  error={cuadricepsError}             
                  value={cuadriceps}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Isquiosurales
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={isquioVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={isquioVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={isquioVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={20}  
                  onChange={(event) => setIsquio(event.target.value)}
                  error={isquioError}             
                  value={isquio}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Tríceps Sural
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={tricepSuralVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={tricepSuralVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={tricepSuralVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={21}  
                  onChange={(event) => setTricepSural(event.target.value)}
                  error={tricepSuralError}             
                  value={tricepSural}
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} md={4}>
                <Button  variant="contained" style={{backgroundColor: '#1769aa', color: '#ffffff', cursor: 'default', minHeight: '100%', minWidth: '100%'}}>
                Adductores
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VM' disabled style={{width:'100%'}}               
                  value={adductoresVm}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VME' disabled style={{width:'100%'}}               
                  value={adductoresVme}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='VMR' disabled style={{width:'100%', textAlign: 'center'}}               
                  value={adductoresVmr}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField variant="outlined" label='Asignación' style={{width:'100%'}} tabIndex={22}  
                  onChange={(event) => setAdductores(event.target.value)}
                  error={adductoresError}             
                  value={adductores}
                />
              </Grid>
            </>

            <Grid container xs={12} justifyContent='center'>
              <Button onClick={() => guardarVol()} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
                Guardar
              </Button>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}