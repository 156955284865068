import React, { useEffect } from 'react';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Autocomplete, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogoRutinaEjercicioEliminar from './DialogoRutinaEjercicioEliminar';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

export default function RutinaHistoricoVer(props) {
  const [rutina, setRutina] = React.useState(0); 
  const [pectoral, setPectoral] = React.useState(0);  
  const [espalda, setEspalda] = React.useState(0);  
  const [biceps, setBiceps] = React.useState(0);  
  const [triceps, setTriceps] = React.useState(0);
  const [delLP, setDelLP] = React.useState(0);  
  const [delA, setDelA] = React.useState(0);  
  const [trapecio, setTrapecio] = React.useState(0);  
  const [abdomen, setAbdomen] = React.useState(0);  
  const [gluteo, setGluteo] = React.useState(0);  
  const [cuadriceps, setCuadriceps] = React.useState(0);  
  const [isquio, setIsquio] = React.useState(0);  
  const [tricepSural, setTricepSural] = React.useState(0);  
  const [adductores, setAdductores] = React.useState(0); 
  const [dia, setDia] = React.useState(0);
  const [arrayEjercicios, setArrayEjercicios] = React.useState([]);
  const [arrayMusculos, setArrayMusculos] = React.useState([]);
  const [arrayMetodos, setArrayMetodos] = React.useState([]);
  const [arrayRutina, setArrayRutina] = React.useState([]);  
  const [banRutina, setBanRutina] = React.useState(0);
  const [ejercicio, setEjercicio] = React.useState('');
  const [ejercicioMusc, setEjercicioMusc] = React.useState('');
  const [ejercicioSets, setEjercicioSets] = React.useState('');
  const [ejercicioRep, setEjercicioRep] = React.useState('');
  const [ejercicioMet, setEjercicioMet] = React.useState('');
  const [ejercicioRer, setEjercicioRer] = React.useState('');
  const [ejercicioDesc, setEjercicioDesc] = React.useState('');  
  const [ejercicioOrden, setEjercicioOrden] = React.useState('');  
  const [arrayDatos, setArrayDatos] = React.useState([]);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);  
  
  useEffect(() => {    
    setRutina(props.idRutina);
    getDatosVol(props.idRutina);    
    getDatosEjercicios();
    getDatosMusculos();
    getDatosMetodos();
  }, [props.userArray.id]);

  function getDatosVol(a){
    axios.post(props.ruta + "backend/clientes/get_datos_vol_calc.php",{
      idCliente: props.arrayDatos.id,
      idRutina: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        setPectoral(data[0].pectoral);
        setEspalda(data[0].espalda);
        setBiceps(data[0].biceps);
        setTriceps(data[0].triceps);
        setDelLP(data[0].delLP);
        setDelA(data[0].delA);
        setTrapecio(data[0].trapecio);
        setAbdomen(data[0].abdomen);
        setGluteo(data[0].gluteo);
        setCuadriceps(data[0].cuadriceps);
        setIsquio(data[0].isquio);
        setTricepSural(data[0].tricepSural);
        setAdductores(data[0].adductores);
      }
    })
  }

  function getDatosEjercicios(){
    axios.post(props.ruta + "backend/ejercicios/get_datos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayEjercicios(data);
      } 
    })
  }

  function getDatosMusculos(){
    axios.post(props.ruta + "backend/ejercicios/get_datos_musculo.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayMusculos(data);
      } 
    })
  }

  function getDatosMetodos(){
    axios.post(props.ruta + "backend/ejercicios/get_datos_metodos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayMetodos(data);
      } 
    })
  }

  function getDatosDiaEjercicios(a){
    axios.post(props.ruta + "backend/clientes/rutina_get_rutina_dia.php",{
      userId: props.userArray.id,
      rutina: rutina,
      dia: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayRutina(data);
        setEjercicioOrden(data[0].ordenUltimo);
        setBanRutina(1);
      } else {
        setBanRutina(0);
      } 
    })
  }

  function irAtras(){
    props.cambiarPag('rutinaHistorico');
    props.setIdRutina(0);
  }

  function cambiarDia(a){
    setDia(a);
    getDatosDiaEjercicios(a);
  }

  const renderRutina = () => {
    if(dia !== 0) {            
      return(
        <Grid container xs={12} spacing={2} justifyContent={'center'}>
          <Grid item xs={1}> 
          </Grid>  
          <Grid item xs={10}>
            <Typography variant="h6" style={{textAlign: 'center'}}>
              Rutina para el día {dia}
            </Typography>
          </Grid><Grid item xs={1}> 
            <Button onClick={() => setDia(0)} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
              X
            </Button>
          </Grid>

          <Grid item xs={12} md={2}>
            <Autocomplete                 
              freeSolo
              value={ejercicio} 
              onChange={(event) => setEjercicio(event.target.value)} 
              onKeyDown={(event) => setEjercicio(event.target.value)} 
              onKeyUp={(event) => setEjercicio(event.target.value)} 
              onClick={(event) => setEjercicio(event.target.value)} 
              options={arrayEjercicios.map((options) => options.ejercicio)}
              renderInput={(params) => 
                <TextField {...params} label="Ejercicios" value={ejercicio}/>
              }
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Musculo"
              value={ejercicioMusc}
              style={{width: '100%'}}
              onChange={(event) => setEjercicioMusc(event.target.value)}
            >
              {arrayMusculos.map((row, index) => {
                return (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nombre}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} md={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Sets"
              value={ejercicioSets}
              style={{width: '100%'}}
              onChange={(event) => setEjercicioSets(event.target.value)}
            >
              <MenuItem value={1}> 1 </MenuItem>
              <MenuItem value={2}> 2 </MenuItem>
              <MenuItem value={3}> 3 </MenuItem>
              <MenuItem value={4}> 4 </MenuItem>
              <MenuItem value={5}> 5 </MenuItem>
              <MenuItem value={6}> 6 </MenuItem>
              <MenuItem value={7}> 7 </MenuItem>
              <MenuItem value={8}> 8 </MenuItem>
            </TextField>
          </Grid>
            
          <Grid item xs={12} md={1}>
            <TextField id="rep" label="Repeticiones" variant="outlined" style={{width:'100%'}}               
              value={ejercicioRep}
              onChange={(event) => setEjercicioRep(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Método de intensificación"
              value={ejercicioMet}
              style={{width: '100%'}}
              onChange={(event) => setEjercicioMet(event.target.value)}
            >
              {arrayMetodos.map((row, index) => {
                return (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nombre}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>            
          <Grid item xs={12} md={1}>
            <TextField id="rer" label="RER" variant="outlined" style={{width:'100%'}}               
              value={ejercicioRer}
              onChange={(event) => setEjercicioRer(event.target.value)}
            />
          </Grid>            
          <Grid item xs={12} md={2}>
            <TextField id="desc" label="Descanso" variant="outlined" style={{width:'100%'}}               
              value={ejercicioDesc}
              onChange={(event) => setEjercicioDesc(event.target.value)}
            />
          </Grid>
          <Grid item xs={1}> 
            <Tooltip title="Agregar">
              <IconButton style={{color:'#000000'}}  onClick={() => agregar()}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Grid> 

        </Grid>
      )
    }
  }  

  function agregar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;
    
    if (ejercicio === "") { ban = 1; } 
    if (ejercicioMusc === "") { ban = 1; }
    if (ejercicioSets === "") { ban = 1; }
    if (ejercicioRep === "") { ban = 1; }
    if (ejercicioMet === "") { ban = 1; }
    if (ejercicioRer === "") { ban = 1; }
    if (ejercicioDesc === "") { ban = 1; }

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/rutina_agregar.php", {
          userId: props.userArray.id,
          idCliente: props.arrayDatos.id,
          rutina: rutina,
          dia: dia,
          ejercicio: ejercicio,
          ejercicioMusc: ejercicioMusc,
          ejercicioSets: ejercicioSets,
          ejercicioRep: ejercicioRep,
          ejercicioMet: ejercicioMet,
          ejercicioRer: ejercicioRer,
          ejercicioDesc: ejercicioDesc,
          ejercicioOrden: ejercicioOrden
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            setRutina(data[0].rutina);
            getDatosVol(data[0].rutina);
            getDatosDiaEjercicios(dia);
            setEjercicio('');
            setEjercicioMusc('');
            setEjercicioSets('');
            setEjercicioRep('');
            setEjercicioMet('');
            setEjercicioRer('');
            setEjercicioDesc('');
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Se ha guardado correctamente");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    }
  }

  const renderRutinaEjercicios = () => {
    if(dia !== 0 && banRutina !== 0) {      
      return(
        <TableContainer>
          <Table
            sx={{ minWidth: '99%' }}
            aria-labelledby="tableTitle"
            size='medium'
            order='asc'
            orderby='producto'
          >
            <TableHead>
              <TableRow>
              < TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '12%'}}
                >
                  
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '25%'}}
                >
                  Ejercicio
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '13%'}}
                >
                  Musculo
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '5%'}}
                >
                  Sets
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '10%'}}
                >
                  Repeticiones
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '15%'}}
                >
                  Método de intensificación
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '10%'}}
                >
                  RER
                </TableCell>
                <TableCell
                  align='center'
                  style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '10%'}}
                >
                  Descanso
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayRutina.map((row, index) => {
                return (
                  <TableRow>
                    <TableCell> 
                      
                      {renderBtnFlechaArriba(row.id, row.orden)}

                      {renderBtnFlechaAbajo(row.id, row.orden)}

                      <Tooltip title="Eliminar">
                        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell> {row.ejercicio} </TableCell>
                    <TableCell> {row.musculo} </TableCell>
                    <TableCell style={{textAlign: 'center'}}> {row.sets} </TableCell>
                    <TableCell style={{textAlign: 'center'}}> {row.rep} </TableCell>
                    <TableCell style={{textAlign: 'center'}}> {row.metodo} </TableCell>
                    <TableCell style={{textAlign: 'center'}}> {row.rer} </TableCell>
                    <TableCell> {row.descanso} </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer> 
      )
    }
  }

  const renderBtnFlechaArriba = (id,a) =>{    
    if(a !== 1 && a !== '1'){
      return(
        <Tooltip title="Subir">
          <IconButton style={{color:'#082342'}}  onClick={() => subir(id,a)}>
            <ArrowCircleUpIcon />
          </IconButton>
        </Tooltip>   
      )
    }
  }

  function subir(id,a){
    axios.post(props.ruta + "backend/rutina/ejercicio_orden_subir.php",{
      userId: props.userArray.id,
      dia: dia,
      rutina: rutina,
      id: id,
      orden: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        getDatosDiaEjercicios(dia);
      } 
    })
  }

  const renderBtnFlechaAbajo = (id,a) =>{  
    var ult = ejercicioOrden - 1;  
    if(a !== ult){
      return(
        <Tooltip title="Bajar">
          <IconButton style={{color:'#082342'}}  onClick={() => bajar(id,a)}>
            <ArrowCircleDownIcon />
          </IconButton>
        </Tooltip>   
      )
    }
  }

  function bajar(id,a){
    axios.post(props.ruta + "backend/rutina/ejercicio_orden_bajar.php",{
      userId: props.userArray.id,
      dia: dia,
      rutina: rutina,
      id: id,
      orden: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        getDatosDiaEjercicios(dia);
      } 
    })
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoRutinaEjercicioEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatosVol = {getDatosVol}
          idRutina = {props.idRutina}
          getDatosDiaEjercicios = {getDatosDiaEjercicios}
          dia = {dia}
          arrayDatos = {arrayDatos}
        />
      )
    }
  } 

  return (
    <Box sx={{ minWidth: '100%'}}>    
      <Grid container xs={12} spacing={2}>
        <Grid item xs={1}>
          <Tooltip title="Atras">
            <IconButton style={{color:'#000000'}}  onClick={() => irAtras('')}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" style={{textAlign: 'center'}}>
          Volumen de entrenamiento pendiente de asignar
          </Typography>
        </Grid>        
        <Grid item xs={1}>
        </Grid>

        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Pectoral' disabled style={{backgroundColor: pectoral > 0 ? '#ffff8d': pectoral == 0 ? '#4caf50': '#ffcdd2', width:'100%'}}               
            value={pectoral}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Espalda' disabled style={{backgroundColor: espalda > 0 ? '#ffff8d': espalda == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={espalda}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Bíceps' disabled style={{backgroundColor: biceps > 0 ? '#ffff8d': biceps == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={biceps}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Tríceps' disabled style={{backgroundColor: triceps > 0 ? '#ffff8d': triceps == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={triceps}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Deltoídes (Lat/Post)' disabled style={{backgroundColor: delLP > 0 ? '#ffff8d': delLP == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}
            value={delLP}
          />
        </Grid>        
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Deltoídes Anterior' disabled style={{backgroundColor: delA > 0 ? '#ffff8d': delA == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={delA}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Trapecio' disabled style={{backgroundColor: trapecio > 0 ? '#ffff8d': trapecio == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={trapecio}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Abdomen' disabled style={{backgroundColor: abdomen > 0 ? '#ffff8d': abdomen == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={abdomen}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Glúteo' disabled style={{backgroundColor: gluteo > 0 ? '#ffff8d': gluteo == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={gluteo}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Cuádriceps' disabled style={{backgroundColor: cuadriceps > 0 ? '#ffff8d': cuadriceps == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={cuadriceps}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Isquiosurales' disabled style={{backgroundColor: isquio > 0 ? '#ffff8d': isquio == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={isquio}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Adductores' disabled style={{backgroundColor: adductores > 0 ? '#ffff8d': adductores == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={adductores}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField variant="outlined" label='Tríceps Sural' disabled style={{backgroundColor: tricepSural > 0 ? '#ffff8d': tricepSural == 0 ? '#4caf50': '#ffcdd2',width:'100%'}}               
            value={tricepSural}
          />
        </Grid>
        
        <Grid item xs={12} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h6" style={{color: '#ffffff', textAlign: 'center'}}>
          Seleccione el día para asignar la rutina 
          </Typography>
        </Grid>
        
        <Grid container xs={12} spacing={0}  justifyContent="center" style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>  
          <Grid item xs={12} md={1}>
            <Button onClick={() => cambiarDia(1)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 1
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(2))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 2
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(3))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 3
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(4))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 4
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(5))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 5
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(6))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 6
            </Button>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={(() => cambiarDia(7))} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Día 7
            </Button>
          </Grid>
        </Grid>

        {renderRutina()}
        {renderRutinaEjercicios()}
        {renderDialogoEliminar()}

      </Grid>
    </Box>
  );
}