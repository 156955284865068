import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Outlet } from "react-router-dom";
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import CottageIcon from '@mui/icons-material/Cottage';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import imgLogo from "../img/logo 2.png";

import DialogNotificacion from './Generales/DialogNotificacion'
import DialogoUsuario from './Generales/DialogoUsuario'

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Layout(props) {
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuCatalogosOpen, setMenuCatalogosOpen] = React.useState(false);
  const [menuKpisOpen, setMenuKpisOpen] = React.useState(false);
  const [dialogoUsuario, setDialogoUsuario] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth * 0.8);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));      
      props.setPagina(cookies.get('sethmon_pagina'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        props.setLogin(false)
      }
    }
  }, []);

  
  useEffect(() => {   
    if(width >= 400 && props.login === true){
      setOpen(true)
    }
  }, [props.login]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleClickMenuCatalogos = () => {
    setMenuCatalogosOpen(!menuCatalogosOpen);
  };

  const handleClickMenuKpis = () => {
    setMenuKpisOpen(!menuKpisOpen);
  };

  const renderDialogNotificacion = () =>{
    if(props.dialogNotificacionOpen === true){
      return(
        <DialogNotificacion 
          {...props} 
          dialogNotificacionOpen = {props.dialogNotificacionOpen}
          setDialogNotificacionOpen = {props.setDialogNotificacionOpen}
          dialogNotificacionTipo = {props.dialogNotificacionTipo}
          setDialogNotificacionTipo = {props.setDialogNotificacionTipo}
          dialogNotificacionMensaje = {props.dialogNotificacionMensaje}
          setDialogNotificacionMensaje = {props.setDialogNotificacionMensaje}
        />
      )
    }
  }

  const renderDescripcion = () =>{
    if(width >= 400){
      return(
        <Typography 
          variant="h6"
          style={{
            color: props.colorTres,
            fontFamily: "Poppins",
            fontWeight: "700",
            marginRight: 5 
          }}>
          (Monitoreo fitness)
        </Typography>
      )
    }
  }

  const renderAppBar = () => {
    if(props.login === true){
      return(
        <AppBar position="fixed" open={open} style={{backgroundColor: '#ffffff'}}>
          <Toolbar>
            {renderMenus()}
            <img
              src={imgLogo}
              alt="Logo"
              style={{ maxWidth: "40px", marginRight: 5 }}
            />
            <Typography 
              variant="h6"           
              style={{
                color: props.colorDos,
                fontFamily: "Poppins",
                fontWeight: "700",
                marginRight: 5 
              }}>
              SETHMOON
            </Typography> 
            {renderDescripcion()} 
            <Typography 
              variant="h6"
              sx={{ flexGrow: 1 }}
              style={{
                color: props.colorDos,
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "0.8em",
              }}>
              v{props.version}
            </Typography>
            {renderBotonLogin()} 
          </Toolbar>        
        </AppBar>
      )
    }
  }

  const renderMenus = () => {
    if(props.login === true){
      return(        
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
            style={{color: '#000000', fontStyle: 'bold'}}
          >
            <MenuIcon />
          </IconButton>        
      )
    }
  }

  function cerrarSesion(){    
    setOpen(false);
    props.setUserArray([]); cookies.remove('sethmon_userArray');
    props.setLogin(false); cookies.remove('sethmon_login');
    navigate('/Login');
  }

  const renderBotonLogin = () => {
  if(props.login === true){    
      return(
        <>
          <IconButton style={{color:'#000000'}}  onClick={() => usuario()}>
            <ManageAccountsIcon />
          </IconButton>
          <Button color="inherit"style={{color: '#000000', fontStyle: 'bold'}} onClick={cerrarSesion}>
            Salir
          </Button>
        </>
      ) 
    }
  }

  function usuario(){
    setDialogoUsuario(true);
  }
  
  const renderDialogoUsuario = () =>{    
    if(dialogoUsuario === true){
      return(
        <DialogoUsuario
          {...props}     
          open = {dialogoUsuario}
          setOpen = {setDialogoUsuario}
        />
      )
    }
  }

  const renderMenuHome = () => {
    if (props.checkPermisos("1") || props.checkPermisos("3")) {      
      return(
        <ListItemButton href="/home" style={{backgroundColor: props.pagina === 'home'? props.colorDos:''}}>
          <ListItemIcon>
            <CottageIcon style={{color: '#000000'}} />
          </ListItemIcon>

          <ListItemText primary="Inicio"  style={{color: '#000000'}}/>
        </ListItemButton>
      )
    }
  }   

  const renderMenuHomeCliente = () => {
    if (props.checkPermisos("1") || props.checkPermisos("0")) {      
      return(
        <ListItemButton href="/homeCliente" style={{backgroundColor: props.pagina === 'homeCliente'? props.colorDos:''}}>
          <ListItemIcon>
            <HomeIcon style={{color: '#000000'}} />
          </ListItemIcon>

          <ListItemText primary="Inicio"  style={{color: '#000000'}}/>
        </ListItemButton>
      )
    }
  } 

  const renderMenuAlimentos = () => {
    if (props.checkPermisos("1") || props.checkPermisos("15")) {      
      return(
        <ListItemButton href="/alimentos" style={{backgroundColor: props.pagina === 'alimentos'? props.colorDos:''}}>
          <ListItemIcon>
            <RestaurantIcon style={{color: '#000000'}} />
          </ListItemIcon>
          <ListItemText primary="Alimentos" style={{color: '#000000'}} />
        </ListItemButton>
      )
    }
  } 

  const renderMenuCostos = () => {
    if (props.checkPermisos("1") || props.checkPermisos("7")) {      
      return(
        <ListItemButton href="/costos" style={{backgroundColor: props.pagina === 'costos'? props.colorDos:''}}>
          <ListItemIcon>
            <AttachMoneyIcon style={{color: '#000000'}} />
          </ListItemIcon>
          <ListItemText primary="Costos" style={{color: '#000000'}} />
        </ListItemButton>
      )
    }
  } 

  const renderMenuClientes = () => {
    if (props.checkPermisos("1") || props.checkPermisos("5")) {      
      return(
        <ListItemButton href="/clientes" style={{backgroundColor: props.pagina === 'clientes'? props.colorDos:''}}>
          <ListItemIcon>
            <GroupIcon style={{color: '#000000'}} />
          </ListItemIcon>
          <ListItemText primary="Clientes" style={{color: '#000000'}} />
        </ListItemButton>
      )
    }
  } 

  const renderMenuEjercicios = () => {
    if (props.checkPermisos("1") || props.checkPermisos("19")) {      
      return(
        <ListItemButton href="/ejercicios" style={{backgroundColor: props.pagina === 'ejercicios'? props.colorDos:''}}>
          <ListItemIcon>
            <FitnessCenterIcon style={{color: '#000000'}} />
          </ListItemIcon>
          <ListItemText primary="Ejercicios" style={{color: '#000000'}} />
        </ListItemButton>
      )
    }
  } 

  const renderMenuUsuarios = () => {
    if (props.checkPermisos("1") || props.checkPermisos("6")) {      
      return(
        <ListItemButton href="/usuarios" style={{backgroundColor: props.pagina === 'usuarios'? props.colorDos:''}}>
          <ListItemIcon>
            <GroupIcon style={{color: '#000000'}} />
          </ListItemIcon>
          <ListItemText primary="Usuarios" style={{color: '#000000'}} />
        </ListItemButton>
      )
    }
  }

  const renderBotonMenu = () => {
    if(width < 400) {
      return(        
        <IconButton onClick={handleDrawerClose} style={{color: '#000000', fontStyle: 'bold'}}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex' }} style={{backgroundColor: props.colorTres}}>
      <CssBaseline />
      {renderAppBar()}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },          
        }}
        variant="persistent"
        anchor="left"
        open={open}        
      >
        <DrawerHeader style={{backgroundColor: '#ffffff'}}>
          {renderBotonMenu()}
        </DrawerHeader>
        <Divider />
        <List>          
          {renderMenuHome()}
          {renderMenuHomeCliente()}

          {renderMenuAlimentos()}
          {renderMenuCostos()}
          {renderMenuClientes()}
          {renderMenuEjercicios()}
          {renderMenuUsuarios()}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <Outlet />
        {renderDialogNotificacion()}
      </Main>

      {renderDialogoUsuario()}
    </Box>
  );
}